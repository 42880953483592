export default function ExclamationMark() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#DF5E5E" />
      <path
        d="M13.696 18.2613C13.696 18.7591 13.5093 19.1947 13.136 19.568C12.7876 19.9164 12.3644 20.0907 11.8667 20.0907C11.3689 20.0907 10.9333 19.9164 10.56 19.568C10.1867 19.1947 10 18.7467 10 18.224C10 17.7013 10.1867 17.2658 10.56 16.9173C10.9333 16.5689 11.3689 16.3947 11.8667 16.3947C12.3644 16.3947 12.7876 16.5813 13.136 16.9547C13.5093 17.328 13.696 17.7636 13.696 18.2613ZM10.336 5.53067C10.336 5.10756 10.4978 4.74667 10.8213 4.448C11.1449 4.14933 11.5058 4 11.904 4C12.3271 4 12.688 4.14933 12.9867 4.448C13.3102 4.74667 13.472 5.15733 13.472 5.68C13.472 6.07822 13.3849 7.03644 13.2107 8.55467C13.2107 8.55467 13.0613 9.77422 12.7627 12.2133C12.6382 13.1093 12.5387 13.8311 12.464 14.3787C12.3644 14.7271 12.1902 14.9387 11.9413 15.0133C11.8916 15.0133 11.8044 14.976 11.68 14.9013C11.5556 14.8267 11.456 14.7271 11.3813 14.6027C11.1573 13.408 10.9209 11.7031 10.672 9.488C10.448 7.248 10.336 5.92889 10.336 5.53067Z"
        fill="white"
      />
    </svg>
  );
}
